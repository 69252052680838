import dayjs from "dayjs"
import { Result } from "src/utils/Result"
import { updateReservedMail } from "src/aws/customAPI"
import { AnswerSelectType, MailType, QuestionnaireInput } from "src/aws/API"
import { ContactType } from "src/constants/ContactType"
import { Question } from "src/constants/Questions"
import { AnswerType } from "src/constants/AnswerType"
import { CreateNewContactRequestParams } from "src/types/contact/CreateNewContactRequestParams"
import { execApi } from "src/apis/execApi"
import { appInfo } from "src/constants/AppInfo"
import { convertDateTimeCandidate } from "src/utils/convertDateTimeCandidate"
import { formatDate } from "src/constants/Day"

type UpdateContactRequestParams = {
  accessToken: string
  reservedContactId: string
} & CreateNewContactRequestParams


type UpdateContactSuccess = unknown
type UpdateContactFailure = {
  message: string
}

export const updateReservedContact = (params: UpdateContactRequestParams): Promise<Result<UpdateContactSuccess, UpdateContactFailure>> => {
  return execApi(
    () => updateReservedMail(params.accessToken, {
      input: {
        id: params.reservedContactId,
        mailType: mailTypeMapping[params.contactType],
        title: params.title,
        body: params.body,
        sendTo: {
          allMembersFlg: params.receivers.isAllMembers,
          managersFlg: params.receivers.isAllLeaders,
          groupIds: params.receivers.groupIds,
          memberIds: params.receivers.memberIds,
        },
        questionnaires: params.contactType === "Survey" ? params.questions.map(convertQuestionnaire) : undefined,
        candidateDatetimes: params.contactType === "ScheduleAdjustment" ? params.eventDateCandidates.map(convertDateTimeCandidate) : undefined,
        shareManagerFlg: params.isAddToOtherAdminsHistory ? 1 : 0,
        commentEnableFlg: params.isEnableForComment,
        showAnswerFlg: params.contactType !== "Normal" ? params.isPublishAnswersToMembers : undefined,
        responseDueDatetime: params.contactType !== "Normal" ? params.deadlineDate?.toISOString() : undefined,
        remindDatetime: params.contactType !== "Normal" ? params.reminderDate?.toISOString() : undefined,
        sendScheduledAt: params.reservationDate?.toISOString(),
        sendScheduledDate: params.reservationDate?.toISOString().split('T')[0],
      },
      appInfo
    }),
    _ => undefined,
    {
      identifiers: ["updateContact", "updateMail"]
    }
  )
}

const mailTypeMapping: {[key in ContactType]: MailType} = {
  Normal: MailType.normal,
  Attendance: MailType.attendance,
  Survey: MailType.questionnaire,
  ScheduleAdjustment: MailType.schedule,
  // 以下、使用しない
  Event: MailType.normal,
  Safety: MailType.normal
}

const answerTypeMapping: {[key in AnswerType]: AnswerSelectType} = {
  Single: AnswerSelectType.single,
  Multiple: AnswerSelectType.multiple,
}

const convertQuestionnaire = (question: Question): QuestionnaireInput => ({
  answerSelectType: answerTypeMapping[question.answerType],
  question: question.title,
  choices: question.choices,
})
