import { createNavigationContainerRef, DarkTheme, DefaultTheme, NavigationContainer } from "@react-navigation/native"
import { StackNavigationOptions } from "@react-navigation/stack"
import React, { ComponentProps, memo, useEffect, useRef } from "react"
import { useRecoilState, useSetRecoilState } from "recoil"
import { registerGetRefreshToken, registerInterceptor, registerSetAccessToken } from "src/aws/customAPI"
import { ColorSchemeName, DeviceEventEmitter, Platform, StyleSheet } from "react-native"
import { HeaderLeftComponent } from "src/components/parts/HeaderLeftComponent"
import { CloseModalHeaderLeftButton } from "src/components/parts/headers/CloseModalHeaderLeftButton"
import { Colors } from "src/constants/Colors"
import { ContactAttendanceAnswerScreens } from "src/constants/ContactAttendanceAnswerScreens"
import { ContactScheduleAnswerScreens } from "src/constants/ContactScheduleAnswerScreens"
import { ContactSurveyAnswerScreens } from "src/constants/ContactSurveyAnswerScreens"
import { CreateNewContactScreens } from "src/constants/CreateNewContactScreens"
import { CreateOrEditGroupScreens } from "src/constants/CreateOrEditGroupScreens"
import { CreateOrEditOrganizationScreens } from "src/constants/CreateOrEditOrganizationScreens"
import { MatchingDrawers } from "src/constants/MatchingDrawers"
import { commonModalOptions } from "src/constants/options/commonModalOptions"
import { commonStackedModalOptions } from "src/constants/options/commonStackedModalOptions"
import { Screens } from "src/constants/Screens"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { useAsyncState } from "src/hooks/useAsyncState"
import { useRootScreen } from "src/hooks/useRootScreen"
import { ContactAttendanceAnswerStack } from "src/navigation/ContactNetworkDrawer/ContactAttendanceAnswerStack"
import { ContactScheduleAnswerStack } from "src/navigation/ContactNetworkDrawer/ContactScheduleAnswerStack"
import { ContactSurveyAnswerStack } from "src/navigation/ContactNetworkDrawer/ContactSurveyAnswerStack"
import { CreateOrEditGroupStack } from "src/navigation/CreateOrEditGroupStack"
import { adLoadState, adState } from "src/recoil/atoms/ads/adState"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"
import { maintenanceState } from "src/recoil/atoms/maintenance/maintenanceState"
import { acceptMatchingTermState } from "src/recoil/atoms/matching/acceptMatchingTermState"
import { routeNameState } from "src/recoil/atoms/routeNameState"
import { appVersionsState } from "src/recoil/atoms/updateApp/appVersionsState"
import { newTermsState } from "src/recoil/atoms/updateTerms/newTermsState"
import { useIsAuthorized } from "src/recoil/hooks/authorization/useIsAuthorized"
import { useAccountIdEffect } from "src/recoil/hooks/useAccountIdEffect"
import { accountSelector } from "src/recoil/selectors/account/accountSelector"
import { SelectAccountSchool, selectAccountSchoolOptions } from "src/screens/account/SelectAccountSchool"
import { AuthorizationTop, authorizationTopOptions } from "src/screens/authorization/AuthorizationTop"
import { LoginCode, loginCodeOptions } from "src/screens/authorization/LoginCode"
import { LoginEmail, loginEmailOptions } from "src/screens/authorization/LoginEmail"
import { LoginPhoneNumber, loginPhoneNumberOptions } from "src/screens/authorization/LoginPhoneNumber"
import { SignupCode, signupCodeOptions } from "src/screens/authorization/SignupCode"
import { SignupEmail, signupEmailOptions } from "src/screens/authorization/SignupEmail"
import { ContactAttendanceAnswerDetail } from "src/screens/contact/contactDetail/ContactAttendanceAnswerDetail"
import { ContactAttendanceAnswerOverview } from "src/screens/contact/contactDetail/ContactAttendanceAnswerOverview"
import { options as contactDetailOptions, ContactDetailScreen } from "src/screens/contact/contactDetail/ContactDetailScreen"
import { ContactScheduleAnswerDetail } from "src/screens/contact/contactDetail/ContactScheduleAnswerDetail"
import { ContactScheduleAnswerOverview } from "src/screens/contact/contactDetail/ContactScheduleAnswerOverview"
import { ContactSurveyAnswerDetail } from "src/screens/contact/contactDetail/ContactSurveyAnswerDetail"
import { ContactSurveyAnswerOverview } from "src/screens/contact/contactDetail/ContactSurveyAnswerOverview"
import {
  SelectEventDateCandidatesScreen,
  selectEventDateCandidatesScreenOptions,
} from "src/screens/contact/create/SelectEventDateCandidatesScreen"
import { selectGroupScreenOptions, SelectGroupsScreen } from "src/screens/contact/create/selectReceivers/SelectGroupsScreen"
import { SelectMembersScreen, selectMembersScreenOptions } from "src/screens/contact/create/selectReceivers/SelectMembersScreen"
import { SelectMethodScreen, selectMethodScreenOptions } from "src/screens/contact/create/selectReceivers/SelectMethodScreen"
import { ContactReplyThread, contactReplyThreadOptions } from "src/screens/contact/reply/ContactReplyThread"
import { CreateNewContactScreen, createNewContactScreenOptions } from "src/screens/CreateNewContactScreen"
import DlinkRedirect from "src/screens/DlinkRedirect"
import { Maintenance } from "src/screens/Maintenance"
import {
  CreateOrEditMatchingProfile,
  options as createOrEditMatchingProfileOptions,
} from "src/screens/matching/CreateOrEditMatchingProfile"
import { MatchingReplyThread, MatchingThreadOptions } from "src/screens/matching/reply/MatchingReplyThread"
import { ReportMatchingProfile, options as reportMatchingProfileOptions } from "src/screens/matching/ReportMatchingProfile"
import { MigrationLogin, migrationLoginOptions } from "src/screens/migration/migrationLogin"
import { MigrationMailConfirmation, migrationMailConfirmationOptions } from "src/screens/migration/migrationMailConfirmation"
import { MigrationTeamConfirmation, migrationTeamConfirmationOptions } from "src/screens/migration/migrationTeamConfirmation"
import { MigrationTeamComplete, migrationTeamCompleteOptions } from "src/screens/migration/migrationTeamComplete"
import { MailMigrationAsMember, mailMigrationAsMemberOptions } from "src/screens/migration/mailMigrationAsMember"
import { MigrationTeamSelect, migrationTeamSelectOptions } from "src/screens/migration/migrationTeamSelect"
import NotFoundScreen from "src/screens/NotFoundScreen"
import { AddGroupInOrganization } from "src/screens/organization/AddGroupInOrganization"
import { AddMemberInGroup } from "src/screens/organization/AddMemberInGroup"
import { SelectCategory } from "src/screens/organization/create/SelectCategory"
import { SelectSubCategory } from "src/screens/organization/create/SelectSubCategory"
import { CreateOrEditOrganization } from "src/screens/organization/CreateOrEditOrganization"
import { DeleteOrganization, deleteOrganizationOptions } from "src/screens/organization/DeleteOrganization"
import { EditGroupMembers } from "src/screens/organization/EditGroupMembers"
import { EditMyProfile, editMyProfileOptions } from "src/screens/organization/EditMyProfile"
import {
  EditOrganizationMemberDetail,
  options as editOrganizationMemberDetailOptions,
} from "src/screens/organization/EditOrganizationMemberDetail"
import { GroupMembers, options as groupMembersOptions } from "src/screens/organization/GroupMembers"
import { InvitationDialog, options as invitationDialogOptions } from "src/screens/organization/InvitationDialog"
import { InvitationEmail, options as invitationEmailOptions } from "src/screens/organization/InvitationEmail"
import { InvitationEmailResult, options as invitationEmailResultOptions } from "src/screens/organization/InvitationEmailResult"
import { InvitationMember, options as invitationMemberOptions } from "src/screens/organization/InvitationMember"
import { JoinOrganization, options as joinOrganizationOptions } from "src/screens/organization/JoinOrganization"
import { MigrateOrganizations, options as migrateOrganizationsOptions } from "src/screens/organization/MigrateOrganizations"
import { OrganizationGroups, options as organizationGroupsOptions } from "src/screens/organization/OrganizationGroups"
import {
  OrganizationMemberDetail,
  options as organizationMemberDetailOptions,
} from "src/screens/organization/OrganizationMemberDetail"
import { OrganizationMembers, options as organizationMembersOptions } from "src/screens/organization/OrganizationMembers"
import { PendingInvitations, options as pendingInvitationsOptions } from "src/screens/organization/PendingInvitations"
import { RegisterProfile, registerProfileOptions } from "src/screens/organization/RegisterProfile"
import { ReportOrganization, reportOrganizationOptions } from "src/screens/organization/ReportOrganization"
import { SendingHistory, options as sendingHistoryOptions } from "src/screens/organization/SendingHistory"
import { AccountInfo, options as accountInfoOptions } from "src/screens/settings/AccountInfo"
import {
  AuthorizationPhoneNumberCode,
  options as authorizationPhoneNumberCodeOptions,
} from "src/screens/settings/AccountInfo/AuthorizationPhoneNumberCode"
import { DeleteAccount, options as deleteAccountOptions } from "src/screens/settings/AccountInfo/DeleteAccount"
import { EditEmail, options as editEmailOptions } from "src/screens/settings/AccountInfo/EditEmail"
import {
  EditOrRegisterPhoneNumber,
  options as editOrRegisterPhoneNumberOptions,
} from "src/screens/settings/AccountInfo/EditOrRegisterPhoneNumber"
import { UpdateCode, updateCodeOptions } from "src/screens/settings/AccountInfo/UpdateCode"
import { EditAccount, editAccountOptions } from "src/screens/settings/EditAccount"
import { Information, options as informationOptions } from "src/screens/settings/Information"
import { InformationDetail, informationDetailOptions } from "src/screens/settings/InformationDetail"
import { Inquiry, inquiryOptions } from "src/screens/settings/Inquiry"
import { InquiryConfirm, inquiryConfirmOptions } from "src/screens/settings/InquiryConfirm"
import { licensesScreenOptions, LisensesScreen } from "src/screens/settings/LicensesScreen"
import { NotificationSettings, notificationSettingsOptions } from "src/screens/settings/NotificationSettings"
import { SubmittedContact, submittedContactOptions } from "src/screens/SubmittedContact"
import { UpdateAppInfo } from "src/screens/UpdateAppInfo"
import { setUrlQuery } from "src/storage/query"
import { analyticsWrap } from "src/tags/analytics"
import { CreateNewContactStack } from "./CreateNewContactStack"
import { CreateOrEditOrganizationStack } from "./CreateOrEditOrganizationStack"
import LinkingConfiguration from "./LinkingConfiguration"
import { RootStack } from "./RootStack"
import { ScreenOptions as RootStackScreenOptions } from "./RootStack/ScreenOptions"
import { OfferCreateModal } from "src/screens/matching/OfferCreate"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"
import { OfferSearchModal } from "src/screens/matching/OfferSearchModal"
import { RegisterAccount, RegisterAccountOptions } from "src/components/projects/RegisterAccount"
import { MailInviteMemberToMigrateTeam } from "src/screens/organization/MailInviteMemberToMigrateTeam"
import { EditMigrateOrganizationStack } from "./EditMigrateOrganizationStack"
import { EditMigrateOrganizationScreens } from "src/constants/EditMigrateOrganizationScreens"
import { SendMemberInMigrate } from "src/screens/organization/SendMemberInMigrate"
import {
  MigrateOrRegisterAccount,
  MigrateOrRegisterAccountOptions,
} from "src/components/projects/RegisterAccount/MigrateOrRegisterAccount"
import { MigrationAsMemberFinish, migrationAsMemberFinishOptions } from "src/screens/migration/migrationAsMemberFinish"
import { InquiryPaidTeam, InquiryPaidTeamOptions } from "src/screens/organization/InquiryPaidTeam"
import { ExtendPaidTeam, extendPaidTeamOptions } from "src/screens/organization/ExtendPaidTeam"
import registerApplicantInformation, {
  registerApplicantInformationOptions,
} from "src/screens/registerPaidTeam/registerApplicantInformation"
import registerPlan, { registerPlanOptions } from "src/screens/registerPaidTeam/registerPlan"
import confirmPlan, { confirmPlanOptions } from "src/screens/registerPaidTeam/confirmPlan"
import { ApplicantAuthorityTransfer } from "src/screens/organization/ApplicantAuthorityTransfer"
import { commonHeaderOptionsOnNestedScreen } from "src/constants/options/commonHeaderOptions"
import CancelOrder, { CancelOrderOption } from "src/screens/registerPaidTeam/CancelOrder"
import ConfirmationTransferApplicantAuthority from "src/screens/organization/ConfirmationTransferApplicantAuthority"
import confirmApplicantInfo, { confirmApplicantInfoOptions } from "src/screens/registerPaidTeam/confirmApplicantInfo"
import confirmUpdatePlan, { confirmUpdatePlanOptions } from "src/screens/registerPaidTeam/confirmUpdatePlan"
import { OrganizationManagerScreens } from "src/constants/OrganizationManagerScreens"
import {
  organizationRefreshPermissionState,
  organizationRefreshState,
} from "src/recoil/atoms/organization/organizationRefreshPermissionState"
import { ContactNetworkDrawers } from "src/constants/ContactNetworkDrawers"
import { ContactListCommentScreens } from "src/constants/ContactListCommentScreens"
import {
  ContactListCommentOverview,
  ContactListCommentOverviewOption,
} from "src/screens/contact/contactDetail/ContactListCommentOverview"
import { ContactListCommentStack } from "./ContactNetworkDrawer/ContactListCommentStack"
import { ContactScheduleCommentDetail } from "src/screens/contact/contactDetail/ContactScheduleCommentDetail"
import { ContactSurveyCommentDetail } from "src/screens/contact/contactDetail/ContactSurveyCommentDetail"

type NavigationProps = { colorScheme: ColorSchemeName }
declare global {
  interface Window {
    googletag: any
  }
}
export const Navigation = memo<NavigationProps>(({ colorScheme }) => {
  const routeNameRef = useRef<string>()
  const navigationRef = createNavigationContainerRef()
  const [maintenance, setMaintenance] = useRecoilState(maintenanceState)
  const [appVersions, setAppVersions] = useRecoilState(appVersionsState)
  const setTerms = useSetRecoilState(newTermsState)
  const [tokens, setTokens] = useAsyncState(tokensState)
  const setRouteName = useSetRecoilState(routeNameState)
  const [oldAdState] = useRecoilState(adState)
  const [oldAdLoadState] = useRecoilState(adLoadState)
  const setAdState = useSetRecoilState(adState)
  const setAdLoadState = useSetRecoilState(adLoadState)
  const [agreeMatchingTerm, setAgreeMatchingTerm] = useRecoilState(acceptMatchingTermState)
  const { value: account } = useAsyncSelector(accountSelector)
  const setNeedRefreshPermission = useSetRecoilState(organizationRefreshPermissionState)
  const setNeedRefreshOrganization = useSetRecoilState(organizationRefreshState)

  useEffect(() => {
    if (Platform.OS === "web") {
      try {
        document.addEventListener("isShowAd", function (e: any) {
          console.log("load wed ads: ", e.value)
        })
      } catch (e) {
        console.log(e)
      }
    } else {
      const subscription = DeviceEventEmitter.addListener("notShowAd", (newValue) => {
        if (!oldAdState.includes(newValue)) {
          setAdState([...oldAdState, newValue])
        }
      })
      const subscriptionLoad = DeviceEventEmitter.addListener("successLoadAd", (newValue) => {
        if (!oldAdLoadState.includes(newValue)) {
          setAdLoadState((prevAdState) => [...prevAdState, newValue])
        }
      })
      return () => {
        subscription.remove()
        subscriptionLoad.remove()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setAdState, setAdLoadState, oldAdLoadState])
  useEffect(() => {
    registerInterceptor((param) => {
      if (param.maintenance) setMaintenance(param.maintenance)
      if (param.appVersions) setAppVersions(param.appVersions)
      if (param.newTerms) setTerms(param.newTerms)
    })
    registerGetRefreshToken(() => tokens?.refreshToken)
    registerSetAccessToken((newAccessToken) =>
      setTokens(
        (prev) =>
          prev && {
            ...prev,
            accessToken: newAccessToken,
          }
      )
    )
  }, [setMaintenance, setAppVersions, setTerms, tokens, setTokens])

  if (maintenance) {
    return <Maintenance />
  }

  if (appVersions) {
    return <UpdateAppInfo />
  }

  return (
    <NavigationContainer
      linking={LinkingConfiguration}
      theme={
        colorScheme === "dark"
          ? { ...DarkTheme, colors: { ...DarkTheme.colors, background: "#000" } }
          : { ...DefaultTheme, colors: { ...DefaultTheme.colors, background: "#fff" } }
      }
      ref={navigationRef}
      onReady={() => {
        routeNameRef.current = navigationRef.current?.getCurrentRoute()?.name
        const Route = navigationRef?.current?.getState()
        const path: string = Route?.routes[0].path ?? ""
        // 招待リンクのコードを保存
        setUrlQuery(path, "code")
      }}
      onStateChange={async () => {
        const previousRouteName = routeNameRef.current
        const currentRouteName = navigationRef.current?.getCurrentRoute()?.name
        const currentRouteOption = navigationRef.current?.getCurrentOptions() as any
        if (previousRouteName !== currentRouteName) {
          if (currentRouteName != null) setRouteName(currentRouteName)
          if (
            Object.values<string>(MatchingDrawers).includes(currentRouteName ?? "") &&
            currentRouteName !== MatchingDrawers.OtherMatchingOffer &&
            currentRouteName !== MatchingDrawers.MatchingOfferDetail
          ) {
            if (!agreeMatchingTerm) {
              setAgreeMatchingTerm(!!account?.matchingTermsAgreedAt)
            }
          }

          if (currentRouteName === OrganizationManagerScreens.OrganizationDetail) {
            setNeedRefreshPermission(true)
          } else if (previousRouteName === OrganizationManagerScreens.OrganizationDetail) {
            setNeedRefreshPermission(false)
          }

          if (Platform.OS !== "web") {
            if (
              currentRouteName === ContactNetworkDrawers.InboxScreen ||
              currentRouteName === ContactNetworkDrawers.OutboxScreen ||
              currentRouteName === ContactNetworkDrawers.TrashBoxScreen ||
              currentRouteName === ContactNetworkDrawers.TemplateScreen ||
              currentRouteName === ContactNetworkDrawers.DraftScreen
            ) {
              setNeedRefreshOrganization(true)
            } else if (
              previousRouteName === ContactNetworkDrawers.InboxScreen ||
              previousRouteName === ContactNetworkDrawers.OutboxScreen ||
              previousRouteName === ContactNetworkDrawers.TrashBoxScreen ||
              previousRouteName === ContactNetworkDrawers.TemplateScreen ||
              previousRouteName === ContactNetworkDrawers.DraftScreen
            ) {
              setNeedRefreshOrganization(false)
            }
          }

          await analyticsWrap.logScreenView(currentRouteOption?.title ?? currentRouteName)

          setAdState([])
        }
        routeNameRef.current = currentRouteName
      }}
    >
      <RootNavigator />
    </NavigationContainer>
  )
})
Navigation.displayName = "Navigation"

const RootNavigator = memo(() => {
  const isAuthorized = useIsAuthorized()
  const isPCScreen = useCheckPCScreen()
  const { RootScreen, rootScreenOptions } = useRootScreen()
  useAccountIdEffect()

  return (
    <RootStack.Navigator initialRouteName={isAuthorized ? Screens.Root : Screens.AuthorizationTop}>
      {isAuthorized ? (
        <>
          <RootStack.Screen name={Screens.Root as any} component={RootScreen} options={rootScreenOptions} />
          <RootStack.Screen
            name={Screens.RegisterAccount as any}
            component={RegisterAccount}
            options={RegisterAccountOptions}
          />
          <RootStack.Screen
            name={Screens.MigrateOrRegisterAccount}
            component={MigrateOrRegisterAccount}
            options={MigrateOrRegisterAccountOptions}
          />
          <RootStack.Screen
            name={Screens.CreateOrganizationStack}
            component={CreateOrganizationNavigator}
            options={createOrganizationNavigatorOptions}
          />
          <RootStack.Screen
            name={Screens.EditOrganizationStack}
            component={EditOrganizationNavigator}
            options={editOrganizationNavigatorOptions}
          />
          <RootStack.Screen name={Screens.RegisterProfile} component={RegisterProfile} options={registerProfileOptions} />
          <RootStack.Screen name={Screens.EditMyProfile} component={EditMyProfile} options={editMyProfileOptions} />
          <RootStack.Screen
            name={Screens.CreateNewContactStack}
            component={SelectReceiversNavigator}
            options={selectReceiversNavigatorOptions}
          />
          <RootStack.Screen name={Screens.ContactDetail} component={ContactDetailScreen} options={contactDetailOptions} />
          <RootStack.Screen
            name={Screens.ContactReplyThread}
            component={ContactReplyThread}
            options={contactReplyThreadOptions}
          />
          <RootStack.Screen
            name={Screens.DeleteOrganization}
            component={DeleteOrganization}
            options={deleteOrganizationOptions}
          />
          <RootStack.Screen
            name={Screens.ReportOrganization}
            component={ReportOrganization}
            options={reportOrganizationOptions}
          />
          <RootStack.Screen
            name={Screens.ContactAttendanceAnswerStack}
            component={ContactAttendanceAnswerNavigator}
            options={contactAttendanceAnswerNavigatorOptions}
          />
          <RootStack.Screen
            name={Screens.ContactSurveyAnswerStack}
            component={ContactSurveyAnswerNavigator}
            options={contactSurveyAnswerNavigatorOptions}
          />
          <RootStack.Screen
            name={Screens.ContactScheduleAnswerStack}
            component={ContactScheduleAnswerNavigator}
            options={contactScheduleAnswerNavigatorOptions}
          />
          <RootStack.Screen
            name={Screens.ContactListCommentStack}
            component={ContactListCommentNavigator}
            options={contactListCommentNavigatorOptions}
          />
          <RootStack.Screen
            name={Screens.OrganizationMembers}
            component={OrganizationMembers}
            options={organizationMembersOptions}
          />
          <RootStack.Screen
            name={Screens.OrganizationMemberDetail}
            component={OrganizationMemberDetail}
            options={organizationMemberDetailOptions}
          />
          <RootStack.Screen
            name={Screens.EditOrganizationMemberDetail}
            component={EditOrganizationMemberDetail}
            options={editOrganizationMemberDetailOptions}
          />
          <RootStack.Screen
            name={Screens.CreateOrEditGroupStack}
            component={CreateOrEditGroupNavigator}
            options={createOrEditGroupNavigatorOptions}
          />
          <RootStack.Screen name={Screens.ExtendPaidTeam} component={ExtendPaidTeam} options={extendPaidTeamOptions} />
          <RootStack.Screen name={Screens.GroupMembers} component={GroupMembers} options={groupMembersOptions} />
          <RootStack.Screen
            name={Screens.PendingInvitations}
            component={PendingInvitations}
            options={pendingInvitationsOptions}
          />
          <RootStack.Screen
            name={Screens.OrganizationGroups}
            component={OrganizationGroups}
            options={organizationGroupsOptions}
          />
          <RootStack.Screen name={Screens.InvitationMember} component={InvitationMember} options={invitationMemberOptions} />
          <RootStack.Screen name={Screens.InvitationEmail} component={InvitationEmail} options={invitationEmailOptions} />
          <RootStack.Screen
            name={Screens.InvitationEmailResult}
            component={InvitationEmailResult}
            options={invitationEmailResultOptions}
          />
          <RootStack.Screen name={Screens.SendingHistory} component={SendingHistory} options={sendingHistoryOptions} />
          <RootStack.Screen
            name={Screens.ReportMatchingProfile}
            component={ReportMatchingProfile}
            options={reportMatchingProfileOptions}
          />
          <RootStack.Screen
            name={Screens.CreateOrEditMatchingProfile}
            component={CreateOrEditMatchingProfile}
            options={createOrEditMatchingProfileOptions}
          />
          <RootStack.Screen
            name={Screens.NotificationSettings}
            component={NotificationSettings}
            options={notificationSettingsOptions}
          />
          <RootStack.Screen name={Screens.Information} component={Information} options={informationOptions} />
          <RootStack.Screen name={Screens.InformationDetail} component={InformationDetail} options={informationDetailOptions} />
          <RootStack.Screen name={Screens.AccountInfo} component={AccountInfo} options={accountInfoOptions} />
          <RootStack.Screen name={Screens.EditAccount} component={EditAccount} options={editAccountOptions} />
          <RootStack.Screen
            name={Screens.SelectAccountSchool}
            component={SelectAccountSchool}
            options={selectAccountSchoolOptions}
          />
          <RootStack.Screen name={Screens.EditEmail} component={EditEmail} options={editEmailOptions} />
          <RootStack.Screen name={Screens.UpdateCode} component={UpdateCode} options={updateCodeOptions} />
          <RootStack.Screen
            name={Screens.EditOrRegisterPhoneNumber}
            component={EditOrRegisterPhoneNumber}
            options={editOrRegisterPhoneNumberOptions}
          />
          <RootStack.Screen
            name={Screens.AuthorizationPhoneNumberCode}
            component={AuthorizationPhoneNumberCode}
            options={authorizationPhoneNumberCodeOptions}
          />
          <RootStack.Screen
            name={Screens.MailMigrationAsMember}
            component={MailMigrationAsMember}
            options={mailMigrationAsMemberOptions}
          />
          <RootStack.Screen
            name={Screens.MigrationAsMemberFinish}
            component={MigrationAsMemberFinish}
            options={migrationAsMemberFinishOptions}
          />
          <RootStack.Screen name={Screens.DeleteAccount} component={DeleteAccount} options={deleteAccountOptions} />
          <RootStack.Screen name={Screens.Licenses} component={LisensesScreen} options={licensesScreenOptions} />
          <RootStack.Screen name={Screens.SubmittedContact} component={SubmittedContact} options={submittedContactOptions} />
          <RootStack.Screen name={Screens.Inquiry} component={Inquiry} options={inquiryOptions} />
          <RootStack.Screen name={Screens.InquiryConfirm} component={InquiryConfirm} options={inquiryConfirmOptions} />
          <RootStack.Screen name={Screens.MigrationLogin} component={MigrationLogin} options={migrationLoginOptions} />
          <RootStack.Screen
            name={Screens.MigrationTeamSelect}
            component={MigrationTeamSelect}
            options={migrationTeamSelectOptions}
          />
          <RootStack.Screen
            name={Screens.MigrationTeamConfirmation}
            component={MigrationTeamConfirmation}
            options={migrationTeamConfirmationOptions}
          />
          <RootStack.Screen
            name={Screens.MigrationTeamComplete}
            component={MigrationTeamComplete}
            options={migrationTeamCompleteOptions}
          />
          <RootStack.Screen
            name={Screens.MigrationMailConfirmation}
            component={MigrationMailConfirmation}
            options={migrationMailConfirmationOptions}
          />
          <RootStack.Screen name={Screens.MatchingThread} component={MatchingReplyThread} options={MatchingThreadOptions} />
          <RootStack.Screen name={Screens.InquiryPaidTeam} component={InquiryPaidTeam} options={InquiryPaidTeamOptions} />
          <RootStack.Screen
            name={Screens.MailInviteMemberToMigrateTeam}
            component={MailInviteMemberToMigrateTeam}
            options={{ headerShown: false }}
          />
          <RootStack.Screen
            name={Screens.registerApplicantInformation}
            component={registerApplicantInformation}
            options={registerApplicantInformationOptions}
          />
          <RootStack.Screen name={Screens.registerPlan} component={registerPlan} options={registerPlanOptions} />
          <RootStack.Screen name={Screens.confirmPlan} component={confirmPlan} options={confirmPlanOptions} />
          <RootStack.Screen name={Screens.confirmUpdatePlan} component={confirmUpdatePlan} options={confirmUpdatePlanOptions} />
          <RootStack.Screen name={Screens.cancelOrder} component={CancelOrder} options={CancelOrderOption} />
          <RootStack.Screen
            name={Screens.confirmApplicantInfo}
            component={confirmApplicantInfo}
            options={confirmApplicantInfoOptions}
          />
        </>
      ) : (
        <RootStack.Group>
          <RootStack.Screen name={Screens.AuthorizationTop} component={AuthorizationTop} options={authorizationTopOptions} />
          <RootStack.Screen name={Screens.SignupEmail} component={SignupEmail} options={signupEmailOptions} />
          <RootStack.Screen name={Screens.LoginEmail} component={LoginEmail} options={loginEmailOptions} />
          <RootStack.Screen name={Screens.LoginPhoneNumber} component={LoginPhoneNumber} options={loginPhoneNumberOptions} />
          <RootStack.Screen name={Screens.LoginCode} component={LoginCode} options={loginCodeOptions} />
          <RootStack.Screen name={Screens.SignupCode} component={SignupCode} options={signupCodeOptions} />
        </RootStack.Group>
      )}
      <RootStack.Screen name={Screens.InvitationDialog} component={InvitationDialog} options={invitationDialogOptions} />
      <RootStack.Screen
        name={Screens.MatchingOfferCreate}
        component={OfferCreateModal}
        options={{ ...matchingNavigatorScreenOptions, headerShown: !isPCScreen }}
      />
      <RootStack.Screen
        name={Screens.MatchingOfferSearch}
        component={OfferSearchModal}
        options={{ ...matchingNavigatorScreenOptions, headerShown: !isPCScreen, title: "募集を検索" }}
      />
      <RootStack.Screen name={Screens.JoinOrganization} component={JoinOrganization} options={joinOrganizationOptions} />
      <RootStack.Screen
        name={Screens.MigrateOrganizations}
        component={MigrateOrganizations}
        options={migrateOrganizationsOptions}
      />
      <RootStack.Screen
        name={Screens.EditMigrateOrganizationStack}
        component={EditMigrateOrganizationNavigator}
        options={editMigrateOrganizationNavigatorOptions}
      />
      <RootStack.Screen name={Screens.DlinkRedirect} component={DlinkRedirect} options={{ title: "" }} />
      <RootStack.Screen name={Screens.NotFound} component={NotFoundScreen} options={{ title: "Oops!" }} />
    </RootStack.Navigator>
  )
})
RootNavigator.displayName = "RootNavigator"

const createOrEditOrganizationNavigatorScreenOptionsStyles = StyleSheet.create({
  headerTitleStyle: {
    fontSize: 15,
    fontWeight: "bold",
    color: Colors.greyshBrown,
  },
})
const createOrganizationNavigatorScreenOptions: StackNavigationOptions = {
  ...commonModalOptions,
  title: "団体作成",
  headerTitleStyle: createOrEditOrganizationNavigatorScreenOptionsStyles.headerTitleStyle,
  cardStyle: { flex: 1 },
}

const editMigrateOrganizationNavigatorScreenOptions: StackNavigationOptions = {
  ...commonModalOptions,
  title: "移行した団体",
  headerTitleStyle: createOrEditOrganizationNavigatorScreenOptionsStyles.headerTitleStyle,
  cardStyle: { flex: 1 },
}

const matchingNavigatorScreenOptions: StackNavigationOptions = {
  ...commonModalOptions,
  headerTitleStyle: createOrEditOrganizationNavigatorScreenOptionsStyles.headerTitleStyle,
  cardStyle: { flex: 1 },
}

const getCreateOrEditOrganizationNavigatorScreenOptions =
  (isCloseIcon: boolean): ComponentProps<typeof CreateOrEditOrganizationStack.Navigator>["screenOptions"] =>
  ({ navigation }) => ({
    headerLeft: (props) => <HeaderLeftComponent isCloseIcon={isCloseIcon} goBack={navigation.goBack} {...props} />,
  })
const createOrEditOrganizationNavigatorTopScreenOptions = getCreateOrEditOrganizationNavigatorScreenOptions(true)
const createOrEditOrganizationNavigatorTailScreenOptions = getCreateOrEditOrganizationNavigatorScreenOptions(false)

const CreateOrganizationNavigator = memo(() => {
  return (
    <CreateOrEditOrganizationStack.Navigator screenOptions={createOrganizationNavigatorScreenOptions}>
      <CreateOrEditOrganizationStack.Screen
        name={CreateOrEditOrganizationScreens.SelectCategory}
        component={SelectCategory}
        options={createOrEditOrganizationNavigatorTopScreenOptions}
      />
      <CreateOrEditOrganizationStack.Group screenOptions={createOrEditOrganizationNavigatorTailScreenOptions}>
        <CreateOrEditOrganizationStack.Screen
          name={CreateOrEditOrganizationScreens.SelectSubCategory}
          component={SelectSubCategory}
        />
        <CreateOrEditOrganizationStack.Screen
          name={CreateOrEditOrganizationScreens.CreateOrEditOrganization}
          component={CreateOrEditOrganization}
        />
      </CreateOrEditOrganizationStack.Group>
    </CreateOrEditOrganizationStack.Navigator>
  )
})
CreateOrganizationNavigator.displayName = "CreateOrganizationScreensNavigator"

const createOrganizationNavigatorOptions: RootStackScreenOptions = { presentation: "modal", headerShown: false }

const getEditMigrateOrganizationNavigatorScreenOptions =
  (isCloseIcon: boolean): ComponentProps<typeof EditMigrateOrganizationStack.Navigator>["screenOptions"] =>
  ({ navigation }) => ({
    headerLeft: (props) => <HeaderLeftComponent isCloseIcon={isCloseIcon} goBack={navigation.goBack} {...props} />,
  })
const editMigrateOrganizationNavigatorTopScreenOptions = getEditMigrateOrganizationNavigatorScreenOptions(true)
const editMigrateOrganizationNavigatorTailScreenOptions = getEditMigrateOrganizationNavigatorScreenOptions(false)

const EditMigrateOrganizationNavigator = memo(() => {
  return (
    <EditMigrateOrganizationStack.Navigator screenOptions={editMigrateOrganizationNavigatorScreenOptions}>
      <EditMigrateOrganizationStack.Screen
        name={EditMigrateOrganizationScreens.SendMemberInMigrate}
        component={SendMemberInMigrate}
        options={editMigrateOrganizationNavigatorTopScreenOptions}
      />
    </EditMigrateOrganizationStack.Navigator>
  )
})
EditMigrateOrganizationNavigator.displayName = "EditMigrateOrganizationScreensNavigator"

const editMigrateOrganizationNavigatorOptions: RootStackScreenOptions = { presentation: "modal", headerShown: false }

const editOrganizationNavigatorScreenOptions: StackNavigationOptions = {
  ...commonModalOptions,
  title: "団体設定",
  cardStyle: { flex: 1 },
}
const EditOrganizationNavigator = memo(() => {
  return (
    <CreateOrEditOrganizationStack.Navigator screenOptions={editOrganizationNavigatorScreenOptions}>
      <CreateOrEditOrganizationStack.Screen
        name={CreateOrEditOrganizationScreens.CreateOrEditOrganization}
        component={CreateOrEditOrganization}
        options={createOrEditOrganizationNavigatorTopScreenOptions}
      />
      <CreateOrEditOrganizationStack.Group screenOptions={createOrEditOrganizationNavigatorTailScreenOptions}>
        <CreateOrEditOrganizationStack.Screen
          name={CreateOrEditOrganizationScreens.SelectCategory}
          component={SelectCategory}
        />
        <CreateOrEditOrganizationStack.Screen
          name={CreateOrEditOrganizationScreens.SelectSubCategory}
          component={SelectSubCategory}
        />
      </CreateOrEditOrganizationStack.Group>
    </CreateOrEditOrganizationStack.Navigator>
  )
})
EditOrganizationNavigator.displayName = "EditOrganizationScreensNavigator"

const editOrganizationNavigatorOptions: RootStackScreenOptions = { presentation: "modal", headerShown: false }

// ------------------------------------------------------------------------------------

const CreateOrEditGroupNavigator = memo(() => {
  return (
    <CreateOrEditGroupStack.Navigator screenOptions={{ cardStyle: { flex: 1 } }}>
      <CreateOrEditGroupStack.Group screenOptions={{ headerLeft: CloseModalHeaderLeftButton }}>
        <CreateOrEditGroupStack.Screen
          name={CreateOrEditGroupScreens.AddGroupInOrganization}
          component={AddGroupInOrganization}
          options={{
            ...commonModalOptions,
            title: "グループ作成",
          }}
        />
        <CreateOrEditGroupStack.Screen
          name={CreateOrEditGroupScreens.EditGroupMembers}
          component={EditGroupMembers}
          options={{
            ...commonModalOptions,
            title: "グループ編集",
          }}
        />
      </CreateOrEditGroupStack.Group>
      <CreateOrEditGroupStack.Screen
        name={CreateOrEditGroupScreens.AddMemberInGroup}
        component={AddMemberInGroup}
        options={({ navigation }) => ({
          headerTitleAlign: "center",
          title: "メンバー追加",
          headerLeft: (props) => <HeaderLeftComponent isCloseIcon={false} goBack={navigation.goBack} {...props} />,
        })}
      />
      <CreateOrEditGroupStack.Screen
        name={CreateOrEditGroupScreens.TransferOfApplicantAuthority}
        component={ApplicantAuthorityTransfer}
        options={{
          ...commonHeaderOptionsOnNestedScreen,
          title: "申込者権限の譲渡",
        }}
      />
      <CreateOrEditGroupStack.Screen
        name={CreateOrEditGroupScreens.ConfirmationTransferApplicantAuthority}
        component={ConfirmationTransferApplicantAuthority}
        options={{
          ...commonHeaderOptionsOnNestedScreen,
          title: "申込者権限の譲渡確認",
        }}
      />
    </CreateOrEditGroupStack.Navigator>
  )
})
CreateOrEditGroupNavigator.displayName = "CreateOrEditGroupNavigator"

const createOrEditGroupNavigatorOptions: RootStackScreenOptions = { presentation: "modal", headerShown: false }

// ------------------------------------------------------------------------------------

const SelectReceiversNavigator = memo(() => {
  return (
    <CreateNewContactStack.Navigator>
      <CreateNewContactStack.Screen
        name={CreateNewContactScreens.CreateNewContact}
        component={CreateNewContactScreen}
        options={createNewContactScreenOptions}
      />
      <CreateNewContactStack.Group screenOptions={{ ...commonStackedModalOptions, presentation: undefined }}>
        <CreateNewContactStack.Screen
          name={CreateNewContactScreens.SelectMethod}
          component={SelectMethodScreen}
          options={selectMethodScreenOptions}
        />
        <CreateNewContactStack.Screen
          name={CreateNewContactScreens.SelectGroup}
          component={SelectGroupsScreen}
          options={selectGroupScreenOptions}
        />
        <CreateNewContactStack.Screen
          name={CreateNewContactScreens.SelectMembers}
          component={SelectMembersScreen}
          options={selectMembersScreenOptions}
        />
      </CreateNewContactStack.Group>
      <CreateNewContactStack.Screen
        name={CreateNewContactScreens.SelectEventDateCandidates}
        component={SelectEventDateCandidatesScreen}
        options={selectEventDateCandidatesScreenOptions}
      />
    </CreateNewContactStack.Navigator>
  )
})

const selectReceiversNavigatorOptions: RootStackScreenOptions = { presentation: "modal", headerShown: false }

// ------------------------------------------------------------------------------------

const ContactAttendanceAnswerNavigator = memo(() => {
  return (
    <ContactAttendanceAnswerStack.Navigator screenOptions={{ cardStyle: { flex: 1 } }}>
      <ContactAttendanceAnswerStack.Screen
        name={ContactAttendanceAnswerScreens.ContactAttendanceAnswerOverview}
        component={ContactAttendanceAnswerOverview}
        options={{
          ...commonModalOptions,
          title: "出欠確認 集計結果",
          headerLeft: CloseModalHeaderLeftButton,
        }}
      />
      <ContactAttendanceAnswerStack.Screen
        name={ContactAttendanceAnswerScreens.ContactAttendanceAnswerDetail}
        component={ContactAttendanceAnswerDetail}
        options={({ navigation }) => ({
          ...commonModalOptions,
          title: "出欠確認 集計結果",
          headerLeft: (props) => <HeaderLeftComponent isCloseIcon={false} goBack={navigation.goBack} {...props} />,
        })}
      />
    </ContactAttendanceAnswerStack.Navigator>
  )
})
ContactAttendanceAnswerNavigator.displayName = "ContactAttendanceAnswerNavigator"

const contactAttendanceAnswerNavigatorOptions: RootStackScreenOptions = {
  presentation: "modal",
  headerShown: false,
}

// ------------------------------------------------------------------------------------

const ContactSurveyAnswerNavigator = memo(() => {
  return (
    <ContactSurveyAnswerStack.Navigator screenOptions={{ cardStyle: { flex: 1 } }}>
      <ContactSurveyAnswerStack.Screen
        name={ContactSurveyAnswerScreens.ContactSurveyAnswerOverview}
        component={ContactSurveyAnswerOverview}
        options={{
          ...commonModalOptions,
          title: "アンケート 集計結果",
          headerLeft: CloseModalHeaderLeftButton,
        }}
      />
      <ContactSurveyAnswerStack.Screen
        name={ContactSurveyAnswerScreens.ContactSurveyAnswerDetail}
        component={ContactSurveyAnswerDetail}
        options={({ navigation }) => ({
          ...commonModalOptions,
          title: "アンケート 集計結果",
          headerLeft: (props) => <HeaderLeftComponent isCloseIcon={false} goBack={navigation.goBack} {...props} />,
        })}
      />
    </ContactSurveyAnswerStack.Navigator>
  )
})
ContactSurveyAnswerNavigator.displayName = "ContactSurveyAnswerNavigator"

const contactSurveyAnswerNavigatorOptions: RootStackScreenOptions = {
  presentation: "modal",
  headerShown: false,
}

// ------------------------------------------------------------------------------------

const ContactScheduleAnswerNavigator = memo(() => {
  return (
    <ContactScheduleAnswerStack.Navigator screenOptions={{ cardStyle: { flex: 1 } }}>
      <ContactScheduleAnswerStack.Screen
        name={ContactScheduleAnswerScreens.ContactScheduleAnswerOverview}
        component={ContactScheduleAnswerOverview}
        options={{
          ...commonModalOptions,
          title: "日程調整 集計結果",
          headerLeft: CloseModalHeaderLeftButton,
        }}
      />
      <ContactScheduleAnswerStack.Screen
        name={ContactScheduleAnswerScreens.ContactScheduleAnswerDetail}
        component={ContactScheduleAnswerDetail}
        options={({ navigation }) => ({
          ...commonModalOptions,
          title: "日程調整 集計結果",
          headerLeft: (props) => <HeaderLeftComponent isCloseIcon={false} goBack={navigation.goBack} {...props} />,
        })}
      />
    </ContactScheduleAnswerStack.Navigator>
  )
})
ContactScheduleAnswerNavigator.displayName = "ContactScheduleAnswerNavigator"

const contactScheduleAnswerNavigatorOptions: RootStackScreenOptions = {
  presentation: "modal",
  headerShown: false,
}

// ------------------------------------------------------------------------------------

const ContactListCommentNavigator = memo(() => {
  return (
    <ContactListCommentStack.Navigator screenOptions={{ cardStyle: { flex: 1 } }}>
      <ContactListCommentStack.Screen
        name={ContactListCommentScreens.ContactListCommentOverview}
        component={ContactListCommentOverview}
        options={ContactListCommentOverviewOption}
      />
      <ContactListCommentStack.Screen
        name={ContactListCommentScreens.ContactSurveyCommentDetail}
        component={ContactSurveyCommentDetail}
        options={({ navigation }) => ({
          ...commonModalOptions,
          title: "アンケート 回答詳細",
          headerLeft: (props) => <HeaderLeftComponent isCloseIcon={true} goBack={navigation.goBack} {...props} />,
        })}
      />
      <ContactListCommentStack.Screen
        name={ContactListCommentScreens.ContactScheduleCommentDetail}
        component={ContactScheduleCommentDetail}
        options={({ navigation }) => ({
          ...commonModalOptions,
          title: "日程調整 回答詳細",
          headerLeft: (props) => <HeaderLeftComponent isCloseIcon={true} goBack={navigation.goBack} {...props} />,
        })}
      />
    </ContactListCommentStack.Navigator>
  )
})
ContactAttendanceAnswerNavigator.displayName = "ContactListCommentNavigator"

const contactListCommentNavigatorOptions: RootStackScreenOptions = {
  presentation: "modal",
  headerShown: false,
}
