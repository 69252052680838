import { memo, useCallback, useEffect } from "react"
import { StyleSheet, Text, View } from "react-native"
import { EditButtonForHeader } from "src/components/parts/buttons/EditButtonForHeader"
import { SmallButton } from "src/components/parts/buttons/SmallButton"
import { CircleThumbnail } from "src/components/parts/CircleThumbnail"
import { CustomKeyboardAvoidingView } from "src/components/parts/CustomKeyboardAvoidingView"
import { CustomScrollView } from "src/components/parts/CustomScrollView"
import { RemoveIcon } from "src/components/parts/icons/RemoveIcon"
import { LoadingIndicator } from "src/components/parts/LoadingIndicator"
import { CustomRefreshControl } from "src/components/projects/CustomRefreshControl"
import { Colors } from "src/constants/Colors"
import { MemberTypeLabel } from "src/constants/MemberTypeLabel"
import { commonHeaderOptions } from "src/constants/options/commonHeaderOptions"
import { SchoolGradeLabels } from "src/constants/SchoolGradeOptions"
import { Screens } from "src/constants/Screens"
import { ScreenOptions } from "src/navigation/RootStack/ScreenOptions"
import { useOrganizationMemberDetailData } from "src/recoil/hooks/screens/useOrganizationMemberDetailData"
import { RootStackScreenProps } from "src/types.d"
import { CopyClipboardIcon } from "src/components/parts/icons/CopyClipboardIcon"
import { Button } from "src/components/parts/buttons/Button"
import { TeamCustomQuestion } from "src/aws/API"
import { defaultThumbnail } from "src/constants/defaultThumbnail"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"
import { RootTabs } from "src/constants/RootTabs"
import { OrganizationManagerScreens } from "src/constants/OrganizationManagerScreens"
import { useCheckAllowAccountPermission } from "src/recoil/hooks/screens/useCheckAllowAccountPermission"
import { MemberType } from "src/types/organization/OrganizationMember"

export type OrganizationMemberDetailParams = {
  organizationId: string
  memberId: string
  imageUrl?: string
  allowEditMember: boolean
}

type Props = RootStackScreenProps<typeof Screens.OrganizationMemberDetail>

export const OrganizationMemberDetail = memo<Props>(({ navigation, route }) => {
  const isLargeScreen = useCheckPCScreen()
  const { organizationId, memberId, imageUrl } = route.params

  const { allowDeleteMember } = useCheckAllowAccountPermission({ organizationId })

  const gotoOrganizaitonMembers = useCallback(
    () => navigation.navigate(Screens.OrganizationMembers, { organizationId }),
    [navigation, organizationId]
  )
  const goToOrganizationMembersPC = useCallback(() => {
    navigation.navigate(Screens.Root, {
      screen: RootTabs.OrganizationManagerTab,
      params: {
        screen: OrganizationManagerScreens.OrganizationMembers,
        params: { organizationId: organizationId },
      },
    })
  }, [navigation, organizationId])
  const {
    refreshMemberDetail,
    memberDetail,
    customQuestions,
    execDeleteMember,
    isDeletingMember,
    isLoading,
    copyEmail,
    myProfile,
  } = useOrganizationMemberDetailData(
    organizationId,
    memberId,
    isLargeScreen ? goToOrganizationMembersPC : gotoOrganizaitonMembers
  )

  useEffect(() => {
    refreshMemberDetail()
  }, [refreshMemberDetail])

  useEffect(() => {
    if (myProfile?.memberId === memberId && memberDetail?.memberType) {
      if (memberDetail?.memberType !== MemberType.Representative) {
        navigation.setOptions({
          headerRight: () => null,
        })
      }
    }
  }, [navigation, memberDetail, myProfile, memberId])

  return (
    <CustomKeyboardAvoidingView style={styles.container}>
      {memberDetail != null ? (
        <CustomScrollView refreshControl={<CustomRefreshControl refreshing={isLoading} onRefresh={refreshMemberDetail} />}>
          <View style={isLargeScreen ? { alignItems: "center" } : {}}>
            <View style={[isLargeScreen ? { width: "100%" } : {}]}>
              <View style={[styles.topContainer, isLargeScreen && { width: 600, marginHorizontal: "auto" }]}>
                <CircleThumbnail source={imageUrl ? { uri: imageUrl } : defaultThumbnail} />
                <Text style={styles.name}>{memberDetail.nickname}</Text>
                <Text style={styles.nameKana}>{memberDetail.nicknameKana}</Text>
              </View>
              <View style={[styles.rowsContainer, isLargeScreen ? { alignItems: "center" } : {}]}>
                <View style={[isLargeScreen ? { width: 600 } : {}]}>
                  <View style={styles.rowContainer}>
                    <View style={styles.rowLeftInner}>
                      <View style={styles.rowCircleIcon} />
                      <Text style={styles.rowTitle}>メンバーの種類</Text>
                    </View>
                    <Text style={styles.rowRightText}>{MemberTypeLabel[memberDetail.memberType]}</Text>
                  </View>
                  <View style={styles.rowSeparator} />
                  <View style={styles.rowContainer}>
                    <View style={styles.rowLeftInner}>
                      <View style={styles.rowCircleIcon} />
                      <Text style={styles.rowTitle}>メンバー番号</Text>
                    </View>
                    <Text style={styles.rowRightText}>{memberDetail.editableMemberId}</Text>
                  </View>
                  <View style={styles.rowSeparator} />
                  {myProfile?.memberType === "Representative" ||
                  myProfile?.memberId === memberId ||
                  memberDetail.isShowEmail ? (
                    <View style={styles.emailRowContainer}>
                      <View style={styles.rowLeftInner}>
                        <View style={styles.rowCircleIcon} />
                        <Text style={styles.rowTitle}>メールアドレス</Text>
                      </View>
                      <View style={styles.emailContainer}>
                        <Text style={[styles.rowRightText, styles.emailText]}>{memberDetail.email}</Text>
                        <Button style={styles.copyClipboardButton} onPress={copyEmail}>
                          <CopyClipboardIcon fillColor={Colors.orange} />
                        </Button>
                      </View>
                      <View style={styles.emailContainer}>
                        <Text
                          style={[styles.rowRightText]}
                        >{`本人のメールアドレスは必ず表示されます。ただし団体内個人設定のメールアドレス設定が非公開を選択している場合、他のメンバーには表示されません。`}</Text>
                      </View>
                    </View>
                  ) : null}
                  {customQuestions.includes(TeamCustomQuestion.phoneNumber) &&
                  memberDetail.phoneNumber &&
                  (myProfile?.memberType === "Representative" ||
                    myProfile?.memberId === memberId ||
                    memberDetail.isShowPhoneNumber) ? (
                    <>
                      <View style={styles.rowSeparator} />
                      <View style={styles.rowContainer}>
                        <View style={styles.rowLeftInner}>
                          <View style={styles.rowCircleIcon} />
                          <Text style={styles.rowTitle}>連絡先電話番号</Text>
                        </View>
                        <Text style={styles.rowRightText}>{memberDetail.phoneNumber}</Text>
                      </View>
                    </>
                  ) : null}
                  {customQuestions.includes(TeamCustomQuestion.belongs) &&
                  memberDetail.affiliation &&
                  (myProfile?.memberType === "Representative" ||
                    myProfile?.memberId === memberId ||
                    memberDetail.isShowAffiliation) ? (
                    <>
                      <View style={styles.rowSeparator} />
                      <View style={styles.rowContainer}>
                        <View style={styles.rowLeftInner}>
                          <View style={styles.rowCircleIcon} />
                          <Text style={styles.rowTitle}>所属（学校・会社等）</Text>
                        </View>
                        <Text style={styles.rowRightText}>{memberDetail.affiliation}</Text>
                      </View>
                    </>
                  ) : null}
                  {customQuestions.includes(TeamCustomQuestion.grade) && memberDetail.schoolGrade ? (
                    <>
                      <View style={styles.rowSeparator} />
                      <View style={styles.rowContainer}>
                        <View style={styles.rowLeftInner}>
                          <View style={styles.rowCircleIcon} />
                          <Text style={styles.rowTitle}>学年</Text>
                        </View>
                        <Text style={styles.rowRightText}>
                          {memberDetail.schoolGrade ? SchoolGradeLabels[memberDetail.schoolGrade] : ""}
                        </Text>
                      </View>
                    </>
                  ) : null}
                </View>
              </View>
              {allowDeleteMember ? (
                <View style={styles.removeButtonContainer}>
                  <SmallButton onPress={execDeleteMember} disabled={isDeletingMember}>
                    {isDeletingMember ? (
                      <LoadingIndicator />
                    ) : (
                      <>
                        <RemoveIcon />
                        <Text style={styles.smallButtonLabel}>団体から削除</Text>
                      </>
                    )}
                  </SmallButton>
                </View>
              ) : null}
            </View>
          </View>
        </CustomScrollView>
      ) : (
        <View style={styles.loadingContainer}>
          <LoadingIndicator />
        </View>
      )}
    </CustomKeyboardAvoidingView>
  )
})

export const options: ScreenOptions = ({ navigation, route }) => ({
  ...commonHeaderOptions,
  title: "メンバー詳細",
  headerRight: () => {
    const params = route.params as OrganizationMemberDetailParams
    return params.allowEditMember ? (
      <EditButtonForHeader onPress={() => navigation.navigate(Screens.EditOrganizationMemberDetail, params)} />
    ) : null
  },
})

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.beige,
  },
  topContainer: {
    paddingTop: 58,
    paddingBottom: 26,
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flex: 1,
    paddingHorizontal: 16,
  },
  profileImage: {
    width: 122,
    height: 122,
    borderRadius: 61,
  },
  name: {
    marginTop: 22,
    fontSize: 24,
    fontWeight: "500",
    maxWidth: "100%",
  },
  nameKana: {
    marginTop: 6,
    fontSize: 14,
  },
  rowsContainer: {
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderTopColor: Colors.white2,
    borderBottomColor: Colors.white2,
  },
  emailRowContainer: {
    padding: 16,
    flexDirection: "column",
    alignItems: "flex-start",
  },
  emailContainer: {
    marginTop: 16,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  emailText: {
    maxWidth: "80%",
  },
  copyClipboardButton: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: 38,
    height: 38,
    backgroundColor: Colors.white2,
    borderRadius: 8,
  },
  rowContainer: {
    padding: 16,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  rowLeftInner: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  rowCircleIcon: {
    width: 16,
    height: 16,
    borderRadius: 8,
    backgroundColor: Colors.blush,
  },
  rowTitle: {
    fontSize: 16,
    fontWeight: "bold",
    marginLeft: 4,
  },
  rowRightText: {
    fontSize: 16,
  },
  rowSeparator: {
    height: 1,
    backgroundColor: Colors.white2,
    marginLeft: 15,
  },
  removeButtonContainer: {
    paddingTop: 30,
    justifyContent: "center",
    alignItems: "center",
  },
  smallButtonLabel: {
    marginLeft: 8,
  },
  loadingContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
})
