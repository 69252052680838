export const guideURL = "https://www.ra9plus.jp/guide"

export const deliveryMailReceiveSettingGuideURL = "https://www.ra9plus.jp/guide/delivery_mail_receive_setting/"

export const privacyPolicyURL = "https://www.ra9plus.jp/privacypolicy/"
export const rulePaidURL = "https://www.ra9plus.jp/rule_paid"

export const helpPaidURL = "https://www.ra9plus.jp/pay_service/"

export const faqURL = "https://www.ra9plus.jp/guide/category/faq/"
