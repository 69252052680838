import Constants from "expo-constants"

const awsmobile =
  Constants.expoConfig?.extra?.env == "prd"
    ? {
        aws_project_region: "ap-northeast-1",
        aws_appsync_graphqlEndpoint: "https://n46sapfmajbtdelhb4fkzhyenm.appsync-api.ap-northeast-1.amazonaws.com/graphql",
        aws_appsync_region: "ap-northeast-1",
        aws_appsync_authenticationType: "AWS_LAMBDA",
      }
    : Constants.expoConfig?.extra?.env == "stg"
    ? {
        aws_project_region: "ap-northeast-1",
        aws_appsync_graphqlEndpoint: "https://4k5do2fldvdzdfhypmhbpr2kne.appsync-api.ap-northeast-1.amazonaws.com/graphql",
        aws_appsync_region: "ap-northeast-1",
        aws_appsync_authenticationType: "AWS_LAMBDA",
      }
    : {
        aws_project_region: "ap-northeast-1",
        aws_appsync_graphqlEndpoint: "https://wqujmbc7ufhyjbkkrhim7kod2u.appsync-api.ap-northeast-1.amazonaws.com/graphql",
        aws_appsync_region: "ap-northeast-1",
        aws_appsync_authenticationType: "AWS_LAMBDA",
      }

export default awsmobile
